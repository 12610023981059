<template>
    <header data-bs-theme="dark">
        <nav class="navbar navbar-expand-lg bg-body-tertiary">
            <div class="container-fluid text-white align-middle">
                <div class="d-flex">
                    <box-icon type="solid" name="map" color="white" class="pe-2"></box-icon>
                    <div>R. Luíz Suzigan, 212 Santa Bárbara D’Oeste – SP</div>
                </div>
                <div class="d-flex">
                    <box-icon type="solid" name="phone" color="white" class="pe-2"></box-icon>
                    <div>(19) 98908-5142</div>
                </div>
            </div>
        </nav>
    </header>
</template>