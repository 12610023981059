<template>
    <section style="border: 0px; padding: 0px; margin: 0px;">
        <div class="" data-aos="fade-up" style="border: 0px; padding: 0px; margin: 0px;">
            <div class="row justify-center" data-aos="fade-up" data-aos-delay="200"
                style="border: 0px; padding: 0px; margin: 0px;">
                <div class="col" style="border: 0px; padding: 0px; margin: 0px;">
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3679.306869458735!2d-47.385289524694166!3d-22.75399027936293!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x94c89c11a1342c03%3A0xf051e69b60bf89d8!2sR.%20Luiz%20Suzigan%2C%20212%20-%20Mollon%2C%20Santa%20B%C3%A1rbara%20d'Oeste%20-%20SP%2C%2013456-164!5e0!3m2!1spt-BR!2sbr!4v1710706849472!5m2!1spt-BR!2sbr"
                        width="100%" height="600" frameborder="0" style="border: 0px; padding: 0px; margin: 0px;">
                    </iframe>
                </div>
            </div>
        </div>
    </section>
</template>